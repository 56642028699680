/* eslint-disable prettier/prettier */

export class ApiURIs {
 
  //public static baseUrl: string = "http://localhost:8001/api";
   //public static baseUrl: string = "http://15.207.1.154:3000/api";
  //public static baseUrl: string = "http://localhost:3000/api";
   //public static baseUrl: string = "http://localhost:57885/api";
   public static baseUrl: string = "https://bddot.com/api";
  //public static baseUrl: string = "http://35.154.36.53:3000/api";
  public static chatURL: string = "http://localhost:57885/api";
  //  unauthorize api
  public static readonly login: string = `/Account/login`;
  public static readonly facebookLogin: string = `/Account/FacebookLogin`;
  public static readonly googleLogin: string = `/Account/GoogleLogin`;
  public static readonly register: string = `/Account/Registration`;
  public static readonly verify: string = `/Account/Verify`;
  public static readonly reSendCode: string = `/Account/ReSendCode`;
  public static readonly resetPasswordVerify: string = `/Account/ResetPasswordVerify`;
  public static readonly resetPassword: string = `/Account/ResetPassword`;
  public static readonly SendEmailVerificationCode: string = `/Account/SendEmailVerificationCode`;
  public static readonly setPasswordGuest: string = `/Account/SetPasswordGuest`;

  // user api
  public static readonly UserFileUpload: string = `/User/FileUpload`;
  public static readonly AgentList: string = `/User/AgentList`;
  public static readonly AssignAsAgent: string = `/User/AssignAsAgent`;
  public static readonly removeAsAgent: string = `/User/RemoveAsAgent`;
  public static readonly updateUser: string = `/User/UpdateProfile`;
  public static readonly GetUserAddress: string = `/User/GetUserAddress`;
  public static readonly GetUserList: string = `/User/UserList`;
  public static readonly UserUpdateStatus: string = `/User/UpdateStatus`;

  // doctor detail api
  public static readonly detailGetList: string = `/DoctorDetail/GetList`;
  public static readonly detailGetById: string = `/DoctorDetail/GetById`;
  public static readonly detailGetProfileList: string = `/DoctorDetail/GetProfileList`;
   public static readonly detailGetProfileListForAdmin: string = `/DoctorDetail/GetProfileListForAdmin`;
  public static readonly detailInsert: string = `/DoctorDetail/Insert`;
  public static readonly detailUpdate: string = `/DoctorDetail/Update`;
  public static readonly detailGetByCurrentUser: string = `/DoctorDetail/GetByCurrentUser`;
  public static readonly detailProfileComplete: string = `/DoctorDetail/ProfileSubmit`;

  /// doctor Specialization api
  public static readonly DrSpecializationInsert: string = `/DoctorSpecialization/Insert`;
  public static readonly DrSpecializationDelete: string = `/DoctorSpecialization/Delete`;

  // doctor exprience api
  public static readonly DrExprienceInsert: string = `/DoctorExperience/Insert`;
  public static readonly DrExprienceUpdate: string = `/DoctorExperience/Update`;
  public static readonly DrExprienceDelete: string = `/DoctorExperience/Delete`;

  // doctor exprience api
  public static readonly DrEduInsert: string = `/DoctorEducation/Insert`;
  public static readonly DrEduUpdate: string = `/DoctorEducation/Update`;
  public static readonly DrEduDelete: string = `/DoctorEducation/Delete`;

  //doctor award api
  public static readonly DrAwardInsert: string = `/DoctorAward/Insert`;
  public static readonly DrAwardUpdate: string = `/DoctorAward/Update`;
  public static readonly DrAwardDelete: string = `/DoctorAward/Delete`;

  // doctor chamber
  public static readonly DrChamberInsert: string = `/DoctorChamber/Insert`;
  public static readonly DrChamberUpdate: string = `/DoctorChamber/Update`;
  public static readonly DrChamberListByUser: string = `/DoctorChamber/GetListByUser`;
  public static readonly DrAllChamberListByUser: string = `/DoctorChamber/GetAllChamberByUser`;
  public static readonly DrChamberDelete: string = `/DoctorChamber/Delete`;

  // doctor vacation
  public static readonly DrvacationUpdate: string = `/DoctorVacation/Update`;
  public static readonly DrvacationListByUser: string = `/DoctorVacation/GetListByUser`;
  public static readonly DrvacationInsert: string = `/DoctorVacation/Insert`;
  public static readonly DrvacationDelete: string = `/DoctorVacation/Delete`;

  // doctor schedule
  public static readonly DrScheduleListByUser: string = `/DoctorSchedule/GetListByUser`;
  public static readonly DrScheduleListByChamber: string = `/DoctorSchedule/GetListByChamber`;
  public static readonly DrScheduleInsert: string = `/DoctorSchedule/Insert`;
  public static readonly DrScheduleDelete: string = `/DoctorSchedule/Delete`;
  public static readonly DrScheduleUpdate: string = `/DoctorSchedule/UpdateSchedule`;
  public static readonly DrChamberScheduleList: string = `/DoctorSchedule/GetListByUserAndChamber`;
  //public static readonly DrChamberScheduleList2: string = `/DoctorSchedule/GetListByUserAndChamber2`;
  public static readonly DrChamberScheduleListByDate: string = `/DoctorSchedule/GetListByDate`;
  public static readonly DrChamberScheduleListById: string = `/DoctorSchedule/GetListByUserAndChamberById`;
  public static readonly DrChamberScheduleWeeklyListById: string = `/DoctorChamberSchedule/GetWeeklyListByUser`;

  // doctor chamber schedule
  public static readonly DrChamberScheduleInsert: string = `/DoctorChamberSchedule/Insert`;
  public static readonly DrChamberScheduleByUser: string = `/DoctorChamberSchedule/GetListByUser`;
  public static readonly DrChamberScheduleDeActive: string = `/DoctorChamberSchedule/DeActiveSchedule`;
  public static readonly DrChamberScheduleUpdate: string = `/DoctorChamberSchedule/UpdateSchedule`;

  // doctor appointment
  public static readonly DrAppointmentInsert: string = `/DoctorAppointment/Insert`;
  public static readonly DrAppointmentInsertAdmin: string = `/DoctorAppointment/InsertDr`;

  public static readonly DrAppointmentVerification: string = `/DoctorAppointment/Verification`;
  public static readonly DrAppointmentReSendCode: string = `/DoctorAppointment/ReSendCode`;
  public static readonly DrAppointmentAppUnApp: string = `/DoctorAppointment/ApprovedUnApproved`;
  public static readonly DrAppointmentCancel: string = `/DoctorAppointment/Cancel`;
  public static readonly DrAppointmentGetListByUser: string = `/DoctorAppointment/GetListByUser`;
  public static readonly DrAppointmentGetListByDrId: string = `/DoctorAppointment/GetListByDrId`;
  public static readonly DrAppointmentGetListByAppointmentDate: string = `/DoctorAppointment/GetListByAppointmentDate`;
  public static readonly DrAppointmentGetListByDoctor: string = `/DoctorAppointment/GetListByDoctor`;
  public static readonly DrAppointmentDaysOfMonth: string = `/DoctorAppointment/DaysOfMonth`;
  public static readonly DrAppointmentGetByAppointmentDate: string = `/DoctorAppointment/GetListByAppointmentDate`;
  public static readonly DrAppointmentGetChamberCountByAppointmentDate: string = `/DoctorAppointment/GetChamberCountByAppointmentDate`;
  public static readonly DrAppointmentGetChamberWeeklyCountByAppointmentDate: string = `/DoctorAppointment/GetChamberWeeklyCountByAppointmentDate`;
  public static readonly DrAppointmentSearch: string = `/DoctorAppointment/GetListByPhone`;
  public static readonly DrAppointmentById: string = `/DoctorAppointment/GetAppointmentById`;
  public static readonly DrAppointmentChartData: string = `/DoctorAppointment/AppointmentChart`;

  // doctor Review
  public static readonly DrReviewInsert: string = `/DoctorReview/Insert`;
  public static readonly DrReviewUpdate: string = `/DoctorReview/Update`;
  public static readonly DrReviewDelete: string = `/DoctorReview/Cancel`;
  public static readonly DrReviewGetListByUser: string = `/DoctorReview/GetListByUser`;
  public static readonly DrReviewGetListByDoctor: string = `/DoctorReview/GetListByDoctor`;
  public static readonly DrGetDoctorReview: string = `/DoctorReview/GetDoctorReview`;
  public static readonly DrGetDoctorReviewUserInfo: string = `/DoctorReview/GetDoctorReviewUserInfo`;

  // universal address
  public static readonly UniversalGetAddress: string = `/Universal/GetAddress`;
  public static readonly UniversalGetAutoComplete: string = `/Universal/GetAutoComplete`;
  public static readonly UniversalSms: string = `/Universal/SendSms`;
  public static readonly DrList: string = `/Doctor/GetList`;
  public static readonly FeatureDrList: string = `/Doctor/GeFeatureDrtList`;
  public static readonly DrListForAdmin: string = `/Doctor/GetAllDrList`;
  public static readonly DrGetSearch: string = `/Doctor/GetSearchBySpecializationId`;
  public static readonly DrSearch: string = `/Doctor/Search`;
  public static readonly DrDetails: string = `/Doctor/GetDetails`;
  public static readonly DrGetById: string = `/Doctor/GetById`;
  public static readonly DrGetChambersById: string = `/Doctor/GetChambers`;
  public static readonly DrGetScheduleChamberId: string = `/Doctor/GetScheduleChamberId`;
  public static readonly DrAdvanceSearch: string = `/Doctor/GetAdvanceSearch`;
  public static readonly DoctorApproved: string = `/Doctor/Approved`;
  public static readonly DoctorDeactivate: string = `/Doctor/Deactivate`;
  public static readonly DoctorRevoke: string = `/Doctor/Revoke`;
  public static readonly DrGetSearchForThana: string=`/Doctor/GetThanaSearchBySpecializationId`;
  // hospital detail api

  public static readonly HospitalInsert: string = `/HospitalDetail/Insert`;
  public static readonly HospitalApproved: string = `/HospitalDetail/Approved`;
  public static readonly HospitalDeactivate: string = `/HospitalDetail/Deactivate`;
  public static readonly HospitalSearch: string = `/HospitalDetail/SearchV2`;
  public static readonly DiagnosticSearch: string = `/Hospital/Search`;
  public static readonly HospitalDetails: string = `/Hospital/GetDetails`;
  public static readonly HospitalDetailsById: string = `/HospitalDetail/GetDetailsById`;
  public static readonly HospitalList: string = `/Hospital/GetList`;
  public static readonly HospitalTopList: string = `/Hospital/GetTopList`;

  public static readonly HospitalReviewInsert: string = `/HospitalDetail/SaveReview`;
  public static readonly HospitalReviewUpdate: string = `/HospitalDetail/UpdateReview`;
  public static readonly HospitalReviewByUser: string = `/HospitalDetail/ReviewGetByUser`;
  public static readonly HospitalReview: string = `/HospitalDetail/ReviewGetListById`;

  //Newsletter
  public static readonly NewsLetterInsert: string = `/NewsLetter/Insert`;

  //chat
  public static readonly ChatRating: string = `/ChatManagement/Rating`;
  //HR
  public static readonly GetEmployeeForAgent: string = `/Employee/GetEmployeeForAgent`;

  public static readonly GetAdminDashboard: string = `/Dashboard/DashboardSummary`;
}
